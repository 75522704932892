@import 'src/core/styles/common.module';

.component {
  display: flex;
  min-height: 100vh;
}
.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 16px;
  padding: 16px 0 0;

  &.error {
    max-width: 100%;
  }

  & button {
    padding: 20px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;

    text-align: center;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 1.15px;
    border-radius: 4px;

    border-width: 0px;
    cursor: pointer;

    &.primary {
      border-color: #304591;
      background: #304591;
      color: #ffffff;
    }

    &.secondary {
      background: #e1e5f0;
      color: #304591;
      border-color: #e1e5f0;
    }

    &:disabled {
      cursor: default;
      background: #e1e5f0;
    }
  }
}

.container {
  max-width: 592px;
  width: 100%;
  box-shadow: 0px 0px 2px rgba(42, 40, 49, 0.2),
  0px 0px 24px rgba(18, 16, 26, 0.08), 0px 40px 160px rgba(47, 70, 129, 0.4);
  border-radius: 32px 32px 0px 0px;
  display: flex;
  flex-direction: column;
  margin: 0 auto 0;
  background: #fff;
}
