html body {
  -webkit-font-smoothing: antialiased;
  --content-top-offset: 16px;
  --content-bottom-offset: 40px;
  --content-side-offset: 12px;
}

html, body {
  min-height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
}

#__next {
}

.ReactModal__Overlay {
  z-index: 500;
}

a {
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
  user-select: none;
}


html,
body {
  /* height: 100%;*/
  /*  overflow: hidden;*/
}

@media (min-width: 592px) {
  html {
    background-size: 110% auto;
    background-position: 20% 20%;
    background-attachment: fixed;

  }
}

textarea,
input[type='text'],
input[type='button'],
input[type='submit'],
.input-checkbox {
  appearance: none;
  -webkit-appearance: none;
}

button,
button:focus {
  outline: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

table th{
  background: #F0F2F5;
  padding: 8px 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-align: left;
}

table td{
  padding: 5px;
}
