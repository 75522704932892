@import 'src/core/styles/common.module';

.backUrl {
  color: #304591;
  margin: 24px 0 0px 24px;

}
.link{
  margin-left: 12px;
  color: #304591;
}
