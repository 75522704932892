@font-face {

  font-style: normal;
  font-display: block;
  font-weight: 200;

}

@font-face {
  font-style: normal;
  font-display: block;
  font-weight: 400;
}

@font-face {
  font-family: 'MabryRegularPro';
  font-style: normal;
  font-display: block;
  font-weight: 500;
}

html body * {
  font-family: MabryRegularPro, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
