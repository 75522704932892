.greyTitle {
  position: static;
  left: 0px;
  top: 0px;

  /* android/caption-12|16 */

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: 0.2px;

  /* android/inactive-black */

  color: rgba(54, 57, 77, 0.6);

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: flex-start;
  flex-grow: 0;
  margin: 4px 0;
}

.greyLabel {
  color: rgba(54, 57, 77, 0.6);
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
}

.inputLabel {
  margin: 20px 0;
  display: block;

  & .label {
    position: absolute;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: rgba(0, 0, 0, 0.87);
    margin: -8px 0px 0 16px;
    z-index: 2;
    background-color: white;
  }

  & select,
  & input {
    text-align: center;
    width: 100%;
    border: 1px solid #a1a5b3;
    box-sizing: border-box;
    border-radius: 4px;
    height: 56px;

    &.error {
      border: 2px solid red;
      background-color: rgba(255, 0, 0, 0.3);
    }
  }
}

.error {
  color: rgba(255, 0, 0, 0.8);
  margin: 5px 0;
  display: inline-block;
}

.tab {
  display: inline-block;
  padding: 8px 12px 0px;

  height: 32px;

  /* android/blue-lighter */

  background: #F0F2F5;
  border-radius: 56px;
  cursor: pointer;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-align: center;
  letter-spacing: 0.2px;

  color: #000000;
  margin-right: 8px;

  &:last-of-type {
    margin-right: 0px;
  }

  &.active, &:hover, &:active {
    background: #304591;
    color: #FFFFFF;
  }
}

.button {
  padding: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;

  text-align: center;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 1.15px;
  border-radius: 4px;

  border-width: 0px;
  cursor: pointer;

  &.primary {
    border-color: #304591;
    background: #304591;
    color: #ffffff;
  }

  &.secondary {
    background: #e1e5f0;
    color: #304591;
    border-color: #e1e5f0;
  }

  &:disabled {
    cursor: default;
    background: #e1e5f0;
  }
}

.arrow {
  width: 0;
  height: 0;
  border-color: #304591;
  display: inline-block;
  margin-left: 5px;

  &.up {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-bottom: 5px solid;
    margin-bottom: 2.5px;
  }

  &.down {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-top: 5px solid;
  }

  &.right {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;

    border-left: 5px solid green;
  }

  &.left {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;

    border-right:5px solid blue;
  }

}

.title {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.15px;

  color: #000000;

  margin: 16px 4px 0;
}

.subTitle {
  //styleName: android/subtitle1-16|24;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;

}

.line {
  border-bottom: 1px solid #F0F2F5;
}

.noMargin {
  margin-left: -16px;
  margin-right: -16px;
}

.modal {
  display: block;

  & div {
    width: 100%
  }

  & .modalBody {
    margin-top: 30%
  }
}
.close {
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: block;
  left: calc(100% - 24px);
}
.close:hover {
  opacity: 0.5;
}
.close:before, .close:after {
  position: absolute;
  left: 10px;
  content: ' ';
  height: 24px;
  width: 2px;
  background-color: rgba(0, 0, 0, 0.87);
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
